<template>

  <AuthWrapper>
    <v-card-text class="mt-5">
      <v-row align-content="center" justify="center">
        <v-col cols="12">

          <div class="login-top d-flex flex-column mb-5 mt-4">
            <v-btn class="text-center mx-auto" color="secondary" dark fab large>
              <v-icon dark>mdi-lock</v-icon>
            </v-btn>
            <h3 class="text-h3 text-center mb-3 mt-3">Login</h3>
            <v-card-subtitle v-if="redirectPath" class="text-center blue-grey--text">Please Login To Continue
            </v-card-subtitle>
          </div>

          <v-form v-model="loginFormValidated" @submit.prevent="login">
            <v-text-field
              v-model="loginForm.email"
              :error-messages="errors['email']"
              :rules="validator.email"
              outlined
              placeholder="Email"
              label="Email"
              color="secondary"
            />
            <v-text-field
              v-model="loginForm.password"
              :error-messages="errors['password']"
              :rules="validator.password"
              color="secondary"
              outlined
              placeholder="Password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordShow"
            />
            <v-checkbox
              v-model="loginForm.remember"
              :false-value="false"
              :true-value="true"
              label="Remember me"
              color="secondary"
            />
            <v-btn
              :disabled="!loginFormValidated"
              :loading="loginForm.submitting"
              block
              color="secondary"
              type="submit"
            >Login
            </v-btn>
          </v-form>

          <div class="d-flex mt-3 mb-3 justify-space-between">
            <p>
              <router-link :to="{name: 'Forgot'}">Forgot Password?</router-link>
            </p>
            <p>
              <router-link :to="{name: 'Register'}">Create New Account</router-link>
            </p>
          </div>

          <div class="d-flex mx-auto flex-column">
            <h4 class="text-h6 text-center mt-3 mb-5 d-block">OR</h4>
            <v-btn :to="{name : 'Home'}"  color="secondary" outlined>Back To Home</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </AuthWrapper>
</template>

<script>
import Toast from '@/utils/Toast';
import AuthWrapper from '@/views/auth/AuthWrapper';
import { userEmailRule } from '@/utils/Validation/validationRules';

export default {
  name: 'Login',
  components: { AuthWrapper },
  data: () => ({
    loginFormValidated: false,
    redirectPath: false,
    showPassword: false,
    loginForm: {
      email: '',
      password: '',
      remember: false,
      submitting: false,
    },
    errors: {},
    validator: {
      email: userEmailRule(),
      password: [
        v => !!v || 'Password is required',
        v => v.length > 5 || 'Password must be 6 or more characters'
      ]
    }
  }),
  methods: {
    async login() {

      let data = {
        email: this.loginForm.email,
        password: this.loginForm.password,
        remember: this.loginForm.remember
      };

      this.loginForm.submitting = true;

      let redirectPath = this.$route?.query?.redirect;
      let redirectTo = redirectPath ? { path: redirectPath } : { name: 'Home' };

      try {
        await this.$store.dispatch('app/tryLogin', data);
        this.loginForm.submitting = true;
        await Toast.success('You Have Successfully Logged In', '');
        this.loginForm.submitting = false;
        return this.$router.push(redirectTo);
      } catch (e) {
        this.errors = e.errors;
        await Toast.error(e.message);
        this.loginForm.submitting = false;
      }
    },
    togglePasswordShow(){
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    'loginForm.email': {
      handler(data) {
        if (data) {
          this.errors = '';
        }
      }
    },

    'loginForm.password': {
      handler(data) {
        if (data) {
          this.errors = '';
        }
      }
    }
  },
  mounted() {
    let redirectPath = this.$route?.query?.redirect;
    if (redirectPath) {
      this.redirectPath = true;
    }
  }
};
</script>

<style lang='scss'>
.left-img {
  background-image: url('../../assets/images/bg/login-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  height: 100vh;
}
</style>
